import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useReducer } from 'react';
import { authApi } from '../api/authApi';
import { deviceApi } from '../api/deviceApi';
import { userApi } from '../api/userApi';
import type { User } from '../types/user';
import { isNull, isUndefined } from 'lodash';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends State {
  platform: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const currentTime = Date.now() / 1000;

  const decoded: any = jwtDecode(accessToken);

  return decoded.exp > currentTime;
};

const setSession = (data: any) => {
  if (!data) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  } else {
    const accessToken = data.access_token;
    const refreshToken = data.refresh_token;
    if (accessToken || refreshToken) {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
    }
  }
};

const getAllRegisters = async (): Promise<void> => {
  try {
    // const registers1 = await deviceApi.getAllRegister1();
    // const register2 = await deviceApi.getAllRegister2();
    const allRegisters = await deviceApi?.getAllModbusRegisters();
    localStorage.setItem('registers', JSON.stringify(allRegisters));
    // const registers = [...registers1, ...register2];
    // localStorage.setItem('registers', JSON.stringify(registers));
  } catch (error) {
    console.log(error);
  }
};

// eslint-disable-next-line consistent-return
const getPermissions = async (accessToken: string): Promise<void> => {
  try {
    const permissions: any = await userApi.getUserPermissions(accessToken);
    return permissions?.permissions;
  } catch (error) {
    localStorage.clear();
    window.location.reload();
  }
};

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  if (!localStorage.getItem('locale')) {
    localStorage.setItem('locale', 'nl');
  }
  useEffect(() => {
    dispatch({
      type: 'INITIALIZE',
      payload: {
        isAuthenticated: false,
        user: null,
      }
    });
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const refreshToken = window.localStorage.getItem('refreshToken');
        if (!window.localStorage.getItem('registers') && isValidToken(accessToken)) {
          getAllRegisters();
        }

        const partnerGlobalConfig: any = await userApi.getGlobalConfig();
        if (!isUndefined(partnerGlobalConfig?.data)) {
          localStorage.setItem('partner_settings', JSON.stringify(partnerGlobalConfig?.data));
        }

        if (!isUndefined(partnerGlobalConfig?.distributor_name)) {
          localStorage.setItem('partner_settings', JSON.stringify(partnerGlobalConfig));
        }

        if (isNull(localStorage.getItem('partner_settings')) || isUndefined(localStorage.getItem('partner_settings'))) {
          window.location.reload();
        }

        if (isValidToken(accessToken)) {
          const permissions = await getPermissions(accessToken);
          const user = await authApi.me(accessToken, permissions);
          await dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else if (window.location.pathname.indexOf('login') !== -1 && !accessToken) {
          console.log('login');
        } else if (window.location.pathname.indexOf('forgot-password') !== -1) {
          console.log('forgot-password');
        } else if (window.location.pathname.indexOf('reset-password') !== -1) {
          console.log('reset-password');
        } else if (window.location.pathname.indexOf('/login') !== -1 && !accessToken) {
          window.location.href = '/login';
        } else if (refreshToken && !isValidToken(accessToken)) {
          try {
            const keycloakData = await authApi.refreshSession(refreshToken);
            if (keycloakData) {
              setSession(keycloakData);
            }
          } catch (error) {
            localStorage.clear();
            window.location.reload();
          }
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    const keycloakData = await authApi.login({ email, password });
    if (keycloakData) {
      setSession(keycloakData);
    } else {
      localStorage.clear();
      throw new Error('Account is deactivated');
    }
  };

  const logout = async (): Promise<void> => {
    localStorage.clear();
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (): Promise<void> => {
    await authApi.register();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
