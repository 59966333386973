import type { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import type { SxProps } from '@material-ui/system';
import jwtDecode from 'jwt-decode';
import { isUndefined } from 'lodash';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import getPartnerLogo from 'src/utils/getPartnerLogo';

const useStyles = makeStyles(() => ({
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    width: '85%',
    height: 110,
    objectFit: 'contain',
  },
}));

interface LogoProps {
  sx?: SxProps<Theme>;
}

const Logo: FC<LogoProps> = () => {
  const classes = useStyles();

  let logo = getPartnerLogo();

  if (localStorage.getItem('accessToken')?.length > 0) {
    const decoded: any = jwtDecode(localStorage.getItem('accessToken'));
    const roles = decoded?.realm_access?.roles;
    const isClientUserRegex = new RegExp('^client_');
    const isClientUser = roles?.find((role) => (
      isClientUserRegex?.test(role)
    ));
    if (isClientUser?.length > 0) {
      if (localStorage.getItem('client_logo')?.length > 0 && localStorage.getItem('client_logo') !== 'null') {
        if (!isUndefined(localStorage.getItem('client_logo'))) {
          const clientLogo = localStorage.getItem('client_logo');
          if (clientLogo?.length > 0 || clientLogo !== 'null') {
            logo = `${process.env.REACT_APP_IMAGE_UPLOAD_URL}/images/user/${clientLogo}`;
          }
        }
      }
    }
  }
  return (
    <RouterLink
      to="/"
      className={classes.logoWrapper}
    >
      <img
        className={classes.logo}
        src={logo}
        alt="Logo"
      />
    </RouterLink>
  );
};

export default Logo;
